
body {
  margin: 0;
  font-family: "DM Sans", sans-serif;
  background: rgb(26, 26, 26);
  padding: 0;
}

h1,
h2,
h3,
h4,
h5,
h6
 {
  margin: 0;
  color: #fff;
}

button {
  font-family: inherit;
  border: 0;
  outline: none;
  transition: 0.2s ease-in;
  cursor: pointer;
}
button:disabled {
  cursor: default;
}
::-webkit-scrollbar {
  width: 5px;
}

::-webkit-scrollbar-track {
  background: rgb(30, 50, 65);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: rgba(230, 230, 230, 0.15);
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #555;
}
:root {
  --doc-height: 100%;
 }